@font-face {
  font-family: "Copasetic";
  src: url("./fonts/copasetic.eot"); /* IE9 Compat Modes */
  src: url("./fonts/copasetic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/copasetic.otf") format("opentype"), /* Open Type Font */
    url("./fonts/copasetic.svg") format("svg"), /* Legacy iOS */
    url("./fonts/copasetic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/copasetic.woff") format("woff"), /* Modern Browsers */
    url("./fonts/copasetic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirLTStd";
  src: url("./fonts/AvenirLTStd-Medium.eot"); /* IE9 Compat Modes */
  src: url("./fonts/AvenirLTStd-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/AvenirLTStd-Medium.otf") format("opentype"), /* Open Type Font */
    url("./fonts/AvenirLTStd-Medium.svg") format("svg"), /* Legacy iOS */
    url("./fonts/AvenirLTStd-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/AvenirLTStd-Medium.woff") format("woff"), /* Modern Browsers */
    url("./fonts/AvenirLTStd-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirLTStd";
  src: url("./fonts/AvenirLTStd-Heavy.eot"); /* IE9 Compat Modes */
  src: url("./fonts/AvenirLTStd-Heavy.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/AvenirLTStd-Heavy.otf") format("opentype"), /* Open Type Font */
    url("./fonts/AvenirLTStd-Heavy.svg") format("svg"), /* Legacy iOS */
    url("./fonts/AvenirLTStd-Heavy.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/AvenirLTStd-Heavy.woff") format("woff"), /* Modern Browsers */
    url("./fonts/AvenirLTStd-Heavy.woff2") format("woff2"); /* Modern Browsers */
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'AvenirLTStd', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #FEFEFE;
}

#root {
  height: 100vh;
}