.App {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  padding: 2rem 2rem 4rem 2rem;
  user-select: none;
}

.fade-out {
  opacity:0;
  transition:  opacity 0.5s;
  pointer-events: none;
}

h1 {
  font-family: 'Copasetic';
}

.App footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 45px;
  width: 100%;
  background-color: steelblue;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FEFEFE;
  font-weight: bold;
  cursor: pointer;
}

.App .app-logo {
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
}

.App img.novartis {
  width: 500px;
  max-width: 100%;
}

.App .app-ref {
  margin-top: 1rem;
  white-space: nowrap;
}

@media screen and (max-width: 900px) {
  .App .app-logo {
    width: 150px;
    height: 150px;
  }

  .App .app-ref {
    font-size: 9pt;
  }
}

@media screen and (max-width: 768px) {
  .App {
    padding-top: 1rem;
  }

  .App .app-logo {
    width: 100px;
    height: 100px;
  }

  .App img.novartis {
    width: 350px;
  }

  .App .app-ref,
  .App footer {
    font-size: 9pt;
  }
  
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content {
  top: 20% !important;
  left: 20% !important;
  right: 20% !important;
  bottom: 20% !important;
}

form.password-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

form.password-form span {
  font-size: 24px;
  font-weight: bold;;
}

form.password-form input {
  font-size: 20px;
}

form.password-form button {
  font-size: 20px;
}

form.invalid .invalid-feedback {
  display: block
}

form.invalid .form-control, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}